import React, { useState, useEffect } from 'react'
import Button from '../Button'
import Title from '../Title'

const urlCode = (typeof window !== `undefined` ? (window.location.search.match(/code=(\d+)/) || [])[1] : false)

export default function Connection({ connect }) {
  const [code, setCode] = useState(urlCode || '')
  useEffect(() => {
    if (code.match(/^\d{5}$/)) connect(code)
  }, [code])
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <Title>Entrez le code</Title>
      <input
        type="text"
        className="m-4 bg-transparent text-3xl font-thin tracking-widest text-white"
        style={{ width: 136 }}
        value={code}
        inputMode="numeric"
        pattern="[0-9]*"
        autoComplete="off"
        onChange={e => setCode(e.target.value)}
        autoFocus />
      <span className="absolute mt-1 bg-transparent text-3xl font-thin tracking-widest pointer-events-none">_____</span>
      <Button className="mt-2">Valider</Button>
    </div>
  )
}